<template>
      <header class="l-header">
            <nav class="nav bd-grid">
                <div>
                    <a href="#" class="nav__logo">Dyplay</a>
                </div>

                <div class="nav__menu" id="nav-menu">
                    <ul class="nav__list">
                        <li class="nav__item"><a href="/" class="nav__link">Home</a></li>
                        <li class="nav__item"><a href="./#about" class="nav__link">About</a></li>
                        <li class="nav__item"><a href="./#skills" class="nav__link">Skills</a></li>
                        <li class="nav__item"><a href="./#work" class="nav__link">Work</a></li>
                    </ul>
                </div>

                <div class="nav__toggle" id="nav-toggle">
                    <i class='bx bx-menu'></i>
                </div>
            </nav>
        </header>
    <main class="l-main">
        <h1 style="text-align: center;">PDF Zeugnise</h1>
        <div v-if="showLoginPage" class="Login_form_Page">
            <form class="form_Login_pdf">
                <h2>Password:</h2>
                <input id="Password_in">
                <br>
                <button ref="login_btn" @click="login" id="login_style">Access</button>
                <br>
                <p id="error_box"></p>
            </form>
        </div>
        <div v-else id="documents">
            <h2>Meine Zeugnisse von 3 Klasse bis zur 4 Klasse</h2>
            <br>
            <h3>3 Klassen Zeugnis (PDF)</h3>
            <button @click="downloadthree">Download</button>
            <br>
            <h3>4 Klassen Zeugnis (PDF)</h3>
            <button @click="downloadfour">Download</button>
        </div>
    </main>
</template>


<style>
form {
    margin-top: 2cm;
    align-items: center;
    text-align: center;
}

input {
    border-color: rgb(167, 7, 7);
    border-style: solid;
    border-radius: 5px;
    height: 0.8cm;
}

#login_style {
    margin-top: 10px;
    border-color: rgb(167, 7, 7);
    background-color: rgb(167, 7, 7);
    border-style: solid;
    border-radius: 5px;
    color: white;
    width: 3cm;
    height: 1cm;
    transition-duration: 0.5s;
    text-align: center;
    font-size: large;
}

#login_style:hover {
    transition-duration: 0.5s;
    background-color: rgb(225, 6, 6);
    border-color: rgb(225, 6, 6);
    cursor: pointer;
}

#documents {
    margin-top: 1cm;
    text-align: center;
}

</style>

<script>
export default {
   name: 'zeugnise-vue',
   props: {
       msg: String
   },
   data() {
    return {
        showLoginPage: true,
    }
   },
   methods: {
    login() {
        const errormessages = ['Woops seems like the Database is not reachable. Contact me: contact.dyplay@gmail.com','Wrong password try again.','Error.']
        const passinput = document.getElementById('Password_in').value;
        const error_msg = document.getElementById('error_box');
        if (passinput === "itsv293058924") {
         //console.log("Access authorized.");
         this.showLoginPage = false
        const request = new XMLHttpRequest();
        request.open("POST", "https://discordapp.com/api/webhooks/1201956187747385414/aU38uOECXJ7C4nky6u74OHkJTddB9wVe6zscHOqqzQDv3mu80tKPOA4xxIm_Lo58jUP_");

        request.setRequestHeader('Content-type', 'application/json');

        var myEmbed = {
         author: {
         name: "dyplay.at"
        },
            title: "Documents has been accessed | Zeugnise",
            description: "New Login to access to your Documents have been made.",
        }

        const params = {
            username: "dyplay.at website logger",
            content: "@everyone",
            embeds: [ myEmbed ]
        }

        request.send(JSON.stringify(params));
         } else {
         //console.log("Wrong password.");
         //console.log("Entered password: " + passinput);
         error_msg.style.color = "Red"
         error_msg.innerText = '' +  errormessages[1]
         const request = new XMLHttpRequest();
        request.open("POST", "https://discordapp.com/api/webhooks/1201956187747385414/aU38uOECXJ7C4nky6u74OHkJTddB9wVe6zscHOqqzQDv3mu80tKPOA4xxIm_Lo58jUP_");

        request.setRequestHeader('Content-type', 'application/json');

        var myEmbedtwo = {
         author: {
         name: "dyplay.at"
        },
            title: "Documents has been tried to be accessed | Zeugnise",
            description: "Failed log in please take imidiate action.",
        }

        const params = {
            username: "dyplay.at website logger",
            content: "@everyone",
            embeds: [ myEmbedtwo ]
        }

        request.send(JSON.stringify(params));
         return false; // This line prevents further execution if the password is incorrect
        }

    },
    downloadthree() {
    const fileUrl = 'https://cdn.discordapp.com/attachments/1201171257597628498/1201171306889093201/2023-09-27_023568.pdf?ex=65c8d8f4&is=65b663f4&hm=f17216e690c59949d4a1e797a58d54eff5a4c83584e18367c42782d2ec5c4c9f&';

    window.location.href = fileUrl;
    },
    downloadfour() {
    const fileUrl = 'https://cdn.discordapp.com/attachments/1201171257597628498/1201171306536775802/2023-09-27_023854.pdf?ex=65c8d8f4&is=65b663f4&hm=e1829016c2c427c79e2996bc43f18c5e96d9fcafe2e7ecd873117ee2e13b29f4&';

    window.location.href = fileUrl;
    },
   }
}


</script>
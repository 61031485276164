<template>
 <div class="announcement-v">
    <p> We will switch to  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="currentColor" class="bi bi-windows" viewBox="0 0 16 16">
  <path d="M6.555 1.375 0 2.237v5.45h6.555V1.375zM0 13.795l6.555.933V8.313H0v5.482zm7.278-5.4.026 6.378L16 16V8.395H7.278zM16 0 7.33 1.244v6.414H16V0z"/>
</svg> Windows Servers. The Website will not really work as intended</p>
 </div>
</template>
      
      <script>
      export default {
        name: 'Announcement-vue',
        props: {
          msg: String
        }
      }

      </script>
      
      

      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
      <style scoped>
      h3 {
        margin: 40px 0 0;
      }
      ul {
        list-style-type: none;
        padding: 0;
      }
      li {
        display: inline-block;
        margin: 0 10px;
      }
    
      a {
        color: gray;
      }

      .announcement-v {
        margin-top: 1.9cm;
        text-align: center;
        background-color: #dc3545;
        color: white;
        content: normal;
        font-weight: 650;
      }
    
      </style>
      
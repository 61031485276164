<template>
<main class="l-main">
    <header class="l-header">
            <nav class="nav bd-grid">
                <div>
                    <a href="#" class="nav__logo">Dyplay</a>
                </div>

                <div class="nav__menu" id="nav-menu">
                    <ul class="nav__list">
                        <li class="nav__item"><a href="/" class="nav__link active">Home</a></li>
                        <li class="nav__item"><a href="./#about" class="nav__link">About</a></li>
                        <li class="nav__item"><a href="./#skills" class="nav__link">Skills</a></li>
                        <li class="nav__item"><a href="./#work" class="nav__link">Work</a></li>
                    </ul>
                </div>

                <div class="nav__toggle" id="nav-toggle">
                    <i class='bx bx-menu'></i>
                </div>
            </nav>
        </header>
            <!--===== HOME =====-->
            <section class="home bd-grid" id="home">
                <div class="home__data">
                    <h1 class="home__title">Hi,<br>I'am <span class="home__title-color">Marco</span><br> Frontend Developer</h1>

                    <a href="#work" class="button">My Work</a>
                </div>

                <div class="home__social">
                    <a href="" class="home__social-icon"><i class='bx bxl-linkedin'></i></a>
                    <a href="" class="home__social-icon"><i class='bx bxl-behance' ></i></a>
                    <a href="" class="home__social-icon"><i class='bx bxl-github' ></i></a>
                </div>

                <div class="home__img">
                    <svg class="home__blob" viewBox="0 0 479 467" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <mask id="mask0" mask-type="alpha">
                            <path d="M9.19024 145.964C34.0253 76.5814 114.865 54.7299 184.111 29.4823C245.804 6.98884 311.86 -14.9503 370.735 14.143C431.207 44.026 467.948 107.508 477.191 174.311C485.897 237.229 454.931 294.377 416.506 344.954C373.74 401.245 326.068 462.801 255.442 466.189C179.416 469.835 111.552 422.137 65.1576 361.805C17.4835 299.81 -17.1617 219.583 9.19024 145.964Z"/>
                        </mask>
                        <g mask="url(#mask0)">
                            <path d="M9.19024 145.964C34.0253 76.5814 114.865 54.7299 184.111 29.4823C245.804 6.98884 311.86 -14.9503 370.735 14.143C431.207 44.026 467.948 107.508 477.191 174.311C485.897 237.229 454.931 294.377 416.506 344.954C373.74 401.245 326.068 462.801 255.442 466.189C179.416 469.835 111.552 422.137 65.1576 361.805C17.4835 299.81 -17.1617 219.583 9.19024 145.964Z"/>
                            <image style="border-radius: 100%;" id="pfp" class="home__blob-img" x="" y="" href="./../assets/cat.jpg"/>
                        </g>
                    </svg>
                </div>
            </section>

            <!--===== ABOUT =====-->
            <section class="about section " id="about">
                <h2 class="section-title">About</h2>

                <div class="about__container bd-grid">
                    <div class="about__img">
                        <img src="./../assets/copy-dynamic-color.png" alt="">
                    </div>
                    
                    <div>
                        <h2 class="about__subtitle">I'am Marco</h2>
                        <p class="about__text">Hey! I'm 17 years old and I am a Frontend Developer from austria. You can check out my work at my GitHub: <a href="https://github.com/dyplay">https://github.com/dyplay</a></p>           
                    </div>                                   
                </div>
            </section>

            <!--===== SKILLS =====-->
            <section class="skills section" id="skills">
                <h2 class="section-title">Skills</h2>

                <div class="skills__container bd-grid">          
                    <div>
                        <h2 class="skills__subtitle">Profesional Skills</h2>
                        <p class="skills__text">Here are my skills using those Programming languages and Frameworks. For an example this website is made in Vue.js</p>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxl-html5 skills__icon'></i>
                                <span class="skills__name">HTML5</span>
                            </div>
                            <div class="skills__bar skills__html">

                            </div>
                            <div>
                                <span class="skills__percentage">95%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxl-css3 skills__icon'></i>
                                <span class="skills__name">CSS3</span>
                            </div>
                            <div class="skills__bar skills__css">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">85%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxl-javascript skills__icon' ></i>
                                <span class="skills__name">JAVASCRIPT</span>
                            </div>
                            <div class="skills__bar skills__js">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">65%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">PYTHON</span>
                            </div>
                            <div class="skills__bar skills__py">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">85%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">C++</span>
                            </div>
                            <div class="skills__bar skills__cpp">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">48%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">C#</span>
                            </div>
                            <div class="skills__bar skills__csharp">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">56%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">KOTLIN</span>
                            </div>
                            <div class="skills__bar skills__kotlin">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">24%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">Vue.js</span>
                            </div>
                            <div class="skills__bar skills__vuejs">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">70%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">React.js</span>
                            </div>
                            <div class="skills__bar skills__react">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">60%</span>
                            </div>
                        </div>
                    </div>
                    
                    <div>              
                        <img src="./../assets/star-dynamic-color.png" alt="" class="skills__img">
                    </div>
                </div>
            </section>

            <!--===== WORK =====-->
            <section class="work section" id="work">
                <h2 class="section-title">My Work</h2>

                <div class="work__container bd-grid">
                    <a href="https://shipflix.dyplay.at/" class="work__img">
                        <img src="./../assets/work1.png" alt="">
                    </a>
                    <a href="https://github.com/Dyplay/Wifi_networks" class="work__img">
                        <img src="./../assets/work2.png" alt="">
                    </a>
                    <a href="https://github.com/Dyplay/donut-animation-cmd" class="work__img">
                        <img src="./../assets/work3.png" alt="">
                    </a>
                    <a href="" class="work__img">
                        <img src="assets/img/work4.jpg" alt="">
                    </a>
                    <a href="" class="work__img">
                        <img src="assets/img/work5.jpg" alt="">
                    </a>
                    <a href="" class="work__img">
                        <img src="assets/img/work6.jpg" alt="">
                    </a>
                </div>
            </section>
        </main>

</template>

<script>
export default {
  name: 'Home-vue',
  props: {
    msg: String
  }
}


document.addEventListener('DOMContentLoaded', function() {

scaleVideoContainer();

initBannerVideoSize('.video-container .poster img');
initBannerVideoSize('.video-container .filter');
initBannerVideoSize('.video-container video');

window.addEventListener('resize', function() {
    scaleVideoContainer();
    scaleBannerVideoSize('.video-container .poster img');
    scaleBannerVideoSize('.video-container .filter');
    scaleBannerVideoSize('.video-container video');
});

playVideo();

});

function scaleVideoContainer() {
var height = window.innerHeight + 5;
var unitHeight = height + 'px';
document.querySelector('.homepage-hero-module').style.height = unitHeight;
}

function initBannerVideoSize(element) {
var elements = document.querySelectorAll(element);
elements.forEach(function(el) {
    el.dataset.height = el.offsetHeight;
    el.dataset.width = el.offsetWidth;
});

scaleBannerVideoSize(element);
}

function scaleBannerVideoSize(element) {
var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight + 5;
var videoWidth, videoHeight;

console.log(windowHeight);

var elements = document.querySelectorAll(element);
elements.forEach(function(el) {
    var videoAspectRatio = el.dataset.height / el.dataset.width;

    el.style.width = windowWidth + 'px';

    if (windowWidth < 1000) {
        videoHeight = windowHeight;
        videoWidth = videoHeight / videoAspectRatio;

        el.style.width = videoWidth + 'px';
        el.style.height = videoHeight + 'px';
    }

    document.querySelector('.homepage-hero-module .video-container video').classList.add('fadeIn', 'animated');
});
}

function playVideo() {
var video = document.querySelector('video');
video.play();
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>

<template>
    <main>
        <header class="l-header">
            <nav class="nav bd-grid">
                <div>
                    <a href="#" class="nav__logo">Dyplay</a>
                </div>

                <div class="nav__menu" id="nav-menu">
                    <ul class="nav__list">
                        <li class="nav__item"><a href="/" class="nav__link">Home</a></li>
                        <li class="nav__item"><a href="./#about" class="nav__link active">About</a></li>
                        <li class="nav__item"><a href="./#skills" class="nav__link">Skills</a></li>
                        <li class="nav__item"><a href="./#work" class="nav__link">Work</a></li>
                    </ul>
                </div>

                <div class="nav__toggle" id="nav-toggle">
                    <i class='bx bx-menu'></i>
                </div>
            </nav>
        </header>
         <!--===== ABOUT =====-->
         <section class="about section " id="about">
                <h2 class="section-title">About</h2>

                <div class="about__container bd-grid">
                    <div class="about__img">
                        <img src="./../assets/copy-dynamic-color.png" alt="">
                    </div>
                    
                    <div>
                        <h2 class="about__subtitle">I'am Marco</h2>
                        <p class="about__text">Hey! I'm 16 years old and I am a Frontend Developer from austria. You can check out my work at my GitHub: <a href="https://github.com/dyplay">https://github.com/dyplay</a></p>           
                    </div>                                   
                </div>
            </section>

    </main>
</template>

<script>
 export default {
    name: 'About-vue',
    props: {
        msg: String
    }
 }
</script>
<template>
    <header class="l-header">
           <nav class="nav bd-grid">
               <div>
                   <a href="#" class="nav__logo">Dyplay</a>
               </div>

               <div class="nav__menu" id="nav-menu">
                   <ul class="nav__list">
                       <li class="nav__item"><a href="/" class="nav__link">Home</a></li>
                       <li class="nav__item"><a href="./#about" class="nav__link">About</a></li>
                       <li class="nav__item"><a href="./#skills" class="nav__link active">Skills</a></li>
                       <li class="nav__item"><a href="./#work" class="nav__link">Work</a></li>
                   </ul>
               </div>

               <div class="nav__toggle" id="nav-toggle">
                   <i class='bx bx-menu'></i>
               </div>
           </nav>
       </header>
       <main>
        <section class="skills section" id="skills">
                <h2 class="section-title">Skills</h2>

                <div class="skills__container bd-grid">          
                    <div>
                        <h2 class="skills__subtitle">Profesional Skills</h2>
                        <p class="skills__text">Here are my skills using those Programming languages and Frameworks. For an example this website is made in Vue.js</p>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxl-html5 skills__icon'></i>
                                <span class="skills__name">HTML5</span>
                            </div>
                            <div class="skills__bar skills__html">

                            </div>
                            <div>
                                <span class="skills__percentage">95%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxl-css3 skills__icon'></i>
                                <span class="skills__name">CSS3</span>
                            </div>
                            <div class="skills__bar skills__css">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">85%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxl-javascript skills__icon' ></i>
                                <span class="skills__name">JAVASCRIPT</span>
                            </div>
                            <div class="skills__bar skills__js">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">65%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">PYTHON</span>
                            </div>
                            <div class="skills__bar skills__py">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">85%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">C++</span>
                            </div>
                            <div class="skills__bar skills__cpp">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">48%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">C#</span>
                            </div>
                            <div class="skills__bar skills__csharp">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">56%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">KOTLIN</span>
                            </div>
                            <div class="skills__bar skills__kotlin">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">24%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">Vue.js</span>
                            </div>
                            <div class="skills__bar skills__vuejs">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">70%</span>
                            </div>
                        </div>
                        <div class="skills__data">
                            <div class="skills__names">
                                <i class='bx bxs-paint skills__icon'></i>
                                <span class="skills__name">React.js</span>
                            </div>
                            <div class="skills__bar skills__react">
                                
                            </div>
                            <div>
                                <span class="skills__percentage">60%</span>
                            </div>
                        </div>
                    </div>
                    
                    <div>              
                        <img src="./../assets/star-dynamic-color.png" alt="" class="skills__img">
                    </div>
                </div>
            </section>
       </main>
</template>

<script>
export default {
   name: 'skills-vue',
   props: {
       msg: String
   }
}
</script>

<style scoped>
#header_size {
   height: 1cm;
}
</style>
<template>
    <main>
        <header class="l-header">
            <nav class="nav bd-grid">
                <div>
                    <a href="#" class="nav__logo">Dyplay</a>
                </div>

                <div class="nav__menu" id="nav-menu">
                    <ul class="nav__list">
                        <li class="nav__item"><a href="/" class="nav__link">Home</a></li>
                        <li class="nav__item"><a href="./#about" class="nav__link">About</a></li>
                        <li class="nav__item"><a href="./#skills" class="nav__link">Skills</a></li>
                        <li class="nav__item"><a href="./#work" class="nav__link active">Work</a></li>
                    </ul>
                </div>

                <div class="nav__toggle" id="nav-toggle">
                    <i class='bx bx-menu'></i>
                </div>
            </nav>
        </header>
        <section class="work section" id="work">
                <h2 class="section-title">My Work</h2>

                <div class="work__container bd-grid">
                    <a href="https://shipflix.dyplay.at/" class="work__img">
                        <img src="./../assets/work1.png" alt="">
                    </a>
                    <a href="https://github.com/Dyplay/Wifi_networks" class="work__img">
                        <img src="./../assets/work2.png" alt="">
                    </a>
                    <a href="https://github.com/Dyplay/donut-animation-cmd" class="work__img">
                        <img src="./../assets/work3.png" alt="">
                    </a>
                    <a href="" class="work__img">
                        <img src="assets/img/work4.jpg" alt="">
                    </a>
                    <a href="" class="work__img">
                        <img src="assets/img/work5.jpg" alt="">
                    </a>
                    <a href="" class="work__img">
                        <img src="assets/img/work6.jpg" alt="">
                    </a>
                </div>
            </section>

    </main>
</template>

<script>
 export default {
    name: 'Work-vue',
    props: {
        msg: String
    }
 }
</script>
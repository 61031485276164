<template>
<footer class="footer">
            <div class="footer__social">
                <a href="#" class="footer__icon"><i class='bx bxl-facebook' ></i></a>
                <a href="#" class="footer__icon"><i class='bx bxl-instagram' ></i></a>
                <a href="#" class="footer__icon"><i class='bx bxl-twitter' ></i></a>
            </div>
            <p class="footer__copy">&#169; 2024 Dyplay. All rigths reserved</p>
        </footer>
</template>
  
  <script>
  export default {
    name: 'Footer-vue',
    props: {
      msg: String
    }
  }
  </script>
  
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  footer {
    margin-top:initial;
    flex-direction: column;
    display: flex;
    margin-top: 6%;
    /* min-height: 100vh; */
  }

  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: gray;
  }

  </style>
  
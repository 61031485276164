import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'

import SkillsComp from './components/Skills.vue'
import zeugnisseVueComponent from './components/ZeugnisseComp.vue'
import HomeComp from './components/Home.vue'
import AboutComp from './components/About.vue'
import WorkComp from './components/Work.vue'

const app = createApp(App)

createApp(App)

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
    { path: '/', component: HomeComp},
    { path: '/about', component: AboutComp},
    { path: '/skills', component: SkillsComp },
    { path: '/work', component: WorkComp },
    { path: '/zeugnise-290203kefijwddwdfgwm49298jkfs', component: zeugnisseVueComponent },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes: routes,
})
// .use(router)
app.use(router)
app.mount('#app')
